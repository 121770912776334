import React from 'react';
import axios from "axios";
import './styles.scss';
import { GoogleMapsReviewsProps, GoogleMapsReviewsState } from './interfaces';
import star from '../../images/star.png';
import starOff from '../../images/star-off.png';
import { v4 } from 'uuid';

const apiUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8000/' : 'https://ferraro.ar/';

class GoogleMapsReviews extends React.Component<GoogleMapsReviewsProps, GoogleMapsReviewsState> {

    constructor(props: GoogleMapsReviewsProps) {
        super(props);
        this.state = {
          data: null, isFetching: false, reviewsRate: -1
        };
      }

    componentDidMount() {
        if (!this.state.isFetching) {
            axios.post(`${apiUrl}api/googleMapsReviews`, {}).then((response) => {
                this.setState({...this.state, isFetching: false, data: response.data});
            }).catch(function (error) {
                // handle error
                console.log('error', error);
            }).then(() => {
                // always executed
                this.setState({...this.state, isFetching: false});
            });
        }
    }

    stars(rate: number) {
        const totalStars = 5;
        let returnRateImages: JSX.Element[] = [];
        for (let i = 0; i < totalStars; i++) {
            let image =  (rate >= i) ? star: starOff;
            returnRateImages.push(<img key={`start_key_${i}`} src={image} alt={image} />);
        }
        return returnRateImages;
    }

    render() {
        return <div className="google-maps-reviews-container">
            {(this.state.data && this.state.data.data && this.state.data.data.rating) ?
                <div key={v4()} className="google-maps-title">
                    Google Reviews {this.stars(this.state.data.data.rating)}
                </div> : undefined}

            {(this.state.data && this.state.data.data && this.state.data.data.reviews)
                ? this.state.data.data.reviews.map((review: any, key: number) => {
                    return <div key={key}>
                        <div className="author">
                            <a href={review.author_url}>
                                <img src={review.profile_photo_url} alt={review.author_name} />
                                <div className="name">{review.author_name}</div>
                            </a>
                        </div>
                        <div className="stars-time-container">{
                            this.stars(review.rating).map((image: JSX.Element, key:number) => {
                                return <div className="star-image" key={key}>{image}</div>;
                            })}
                            <div className="relative-time-description">{review.relative_time_description}</div>
                        </div>
                        <div className="description">{review.text}</div>
                    </div>;
                })
                : undefined}
        </div>;
    }
}

export default GoogleMapsReviews;
