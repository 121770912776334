import './styles.scss';
import { FormControl, InputLabel, Input, TextField, FormControlLabel, 
  Checkbox, Modal, Button, ThemeProvider } from '@material-ui/core';
  import { createTheme } from '@material-ui/core/styles';
import React from 'react';
import { ContactFormProps, ContactFormState } from './interfaces';

const checkFormAcceptText = 'Alta en newsletter';
const GOOGLE_RECAPTCHA_SITE_KEY = '6LeeRq8ZAAAAAKLDoj6O0hLYOfCD7FFAN-P9NDeY';
const theme = createTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
  },
});

const apiUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8000/' : 'https://ferraro.ar/';

class ContactFormComponent extends React.Component<ContactFormProps, ContactFormState> {

  constructor(props: ContactFormProps) {
    super(props);
    this.state = {
      contactFormData: {
        name: '', lastName: '', email: '', subject: '', text: '', phone: '',
        checkFormAccept: false
      }, postIsLoading: false, formResponse: '', formErrors: []
    };
  }

  componentDidMount() {
    this.loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`, () => {});  
  }

  handleChange (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState({ ...this.state, contactFormData: {
      ...this.state.contactFormData, checkFormAccept: checked
    }});
  }

  handleInputChange (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, element: string) {
    this.setState({ ...this.state, contactFormData: {
      ...this.state.contactFormData, [element]: e.target.value
    }});
  }

  render () {
    return (
      <div className="contact-form">
        <ThemeProvider theme={theme}>
          {this.state.postIsLoading ? 'Is loading' : undefined}
          <form className="mdl-js-textfield">
            {(!!this.state.formResponse) ? 'Gracias por tu mensaje'
             : <>
              <FormControl fullWidth className="input-form-control">
                <InputLabel htmlFor="name">Nombre</InputLabel>
                <Input id="name" aria-describedby="name"
                  value={this.state.contactFormData.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    return this.handleInputChange(e, 'name');
                  }}
                />
              </FormControl>

              <FormControl fullWidth className="input-form-control">
                <InputLabel htmlFor="lastName">Apellido</InputLabel>
                <Input id="lastName" aria-describedby="last-name"
                  value={this.state.contactFormData.lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    return this.handleInputChange(e, 'lastName');
                  }}
                />
              </FormControl>

              <FormControl  fullWidth className="input-form-control">
                <InputLabel htmlFor="e-mail">Email</InputLabel>
                <Input id="e-mail" aria-describedby="e-mail"
                  value={this.state.contactFormData.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    return this.handleInputChange(e, 'email');
                  }}
                />
              </FormControl>

              <FormControl  fullWidth className="input-form-control">
                <InputLabel htmlFor="e-mail">Teléfono</InputLabel>
                <Input id="phone" aria-describedby="phone"
                  value={this.state.contactFormData.phone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    return this.handleInputChange(e, 'phone');
                  }}
                />
              </FormControl>

              <FormControl  fullWidth className="input-form-control">
              <InputLabel htmlFor="subjet">Asunto</InputLabel>
                <Input id="subjet" aria-describedby="Asunto"
                  value={this.state.contactFormData.subject}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    return this.handleInputChange(e, 'subject');
                  }}
                />
              </FormControl>

              <div className="subject-text-area">
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={6}
                  fullWidth
                  label="Tu consulta"
                  variant="outlined"
                  value={this.state.contactFormData.text}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    return this.handleInputChange(e, 'text');
                  }}
                />
              </div>

              <div className="contact-form-checkbox-container">
              <FormControlLabel
                label={checkFormAcceptText}
                labelPlacement="end"
                control={
                  <Checkbox
                    className="contact-form-checkbox"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                      return this.handleChange(e, checked);
                    }}
                    value={this.state.contactFormData.checkFormAccept}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  }
              />
              </div>

              <Button
                onClick={(e: any) => {
                  return this.onSubmitForm(e); 
                }}
                variant="contained" color="secondary" size="large"
                type="submit"
                disabled={this.state.postIsLoading}>{
                  this.state.postIsLoading ? 'Enviando...' : 'Enviar'
                }</Button>
            </> }

            <div>
              {(this.state.formErrors.length > 0) ?
              <Modal
                    open={true}
                    onClose={() => {
                      this.setState({...this.state, formErrors: []});
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="errors-modal"
                  >
                    <div className="modal-size">
                      <h3 id="server-modal-title">Error</h3>
                      <p id="server-modal-description">
                        {this.state.formErrors.map((e: string, key: number) => {
                          return <div key={`error_key_${key}`}>
                            {e}
                          </div>;
                        })}
                      </p>
                    </div>
                </Modal>
              : undefined }
            </div>
          </form>
        </ThemeProvider>
      </div>
    );
  }

  onSubmitForm(e: any) {
      e.preventDefault();
      const grecaptcha = (window as any).grecaptcha;
      this.setState({...this.state, postIsLoading: true});
      if (grecaptcha.ready) {
        grecaptcha.execute(GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token: any) => {
          fetch(`${apiUrl}api/verify-recaptcha`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              "g-recaptcha-response": token,
              "contact-form-data": this.state.contactFormData
            })
          }).then(res => res.json()).then(res => {
            this.setState({...this.state, postIsLoading: false});
            // setResponse(res);
            this.setState({...this.state, formResponse: res.status,
              formErrors: res.errors
            });
            
            console.log(res.status);
          });
        });
      }
    

      // console.log('token (any): ', token);
      // call a backend API to verify reCAPTCHA response
      
  }

  loadScriptByURL = (id: string, url: string, callback: any) => {
    const isScriptExist = document.getElementById(id);
 
    if (!isScriptExist) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }
 
    if (isScriptExist && callback) callback();
  }

}

export default ContactFormComponent;
