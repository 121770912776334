import ReactDOM from 'react-dom';
import './index.scss';
import ContactFormComponent from './components/contactFormComponent/index';
import GoogleMapsReviews from './components/googleMapsReviews';
import YoutubeComponent from './components/youtube/index';

const loadDiv = (element: DivElement) => {
  let elementDiv = document.getElementById(element.divId);
  if (elementDiv) {
    ReactDOM.render(
      <element.component apiUrl={elementDiv.dataset.apiurl ? elementDiv.dataset.apiurl : ''} />,
      elementDiv
    );
  }
};
 
const divIDsList: DivElement[] = [
  {divId: 'appDiv', component: ContactFormComponent},
  {divId: 'googleMapReviewsDiv', component: GoogleMapsReviews},
  {divId: 'YoutubeDiv', component: YoutubeComponent},
];

divIDsList.forEach((element: DivElement) => {
  loadDiv (element);
});

interface DivElement {
  divId: string;
  component: any; // TODO: find type for component
}