import axios from "axios";
import React, {useState} from "react";
import './styles.scss';

const apiUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8000/' : 'https://ferraro.ar/';

function YoutubeEmbed (params: any) {
    return <div className="video-responsive">
      <iframe
        style={{maxWidth: '100%'}}
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${params.embedData.link}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={params.embedData.name}
      />
    </div>;
};

export default function YoutubeComponent(props: any) {
    const [videos, setVideos] = useState([]);
    const [currentVideo, setCurrentVideo] = useState(0);

    React.useEffect(() => {
      const fetchVideos = async () => {
        axios.get(`${apiUrl}api/youtube`)
          .then((response: any) => {
            setVideos(response.data.response);
          });
        }
        fetchVideos();
      }, []);

    return (
      <div className="videos-app">
        {(videos[currentVideo]) ?
            <YoutubeEmbed embedData={videos[currentVideo]}/>
          : undefined
        }
        <div className="videos-links">
          {
            (videos.length > 0) ?
              videos.map((video: any, counter: number) => {
                return <div key={video.link} className="video-preview-container">
                  {((videos[currentVideo] as any).link !== video.link)
                    ? <div className="video-link-container">
                        <div className="videos-link-title">
                          {video.name}
                        </div>
                        <img className="video-preview-image"
                            onClick={() => {
                              setCurrentVideo(counter)
                            }}
                            src={`https://img.youtube.com/vi/${video.link}/default.jpg`} alt={video.name} />
                      </div>
                      : undefined}
                  </div>
              }) : undefined
          }
        </div>
      </div>
    );
}
  